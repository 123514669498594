






































import Vue from "vue";
import rules from "@/components/account/rules";
import { notifyError, notifySuccess } from "@/components/Notification";

import { getAPI } from "@/api/axios-base";
export default Vue.extend({
  name: "btn-edit-motive",
  props: {
    item: Object,
  },
  data() {
    return {
      DialogEdit: false,
      rules,
      nameitem: "",
      idItem: null,
      loading: false,
    };
  },
  computed: {},
  mounted() {
    this.idItem = this.item.id;
    this.nameitem = this.item.name;
  },
  methods: {
    cancel() {
      this.DialogEdit = false;
      this.loading = false;
      this.$emit("edited");
    },

    update(): void {
      this.loading = true;
      getAPI
        .put("appoiment-type/update/" + this.idItem, { name: this.nameitem })
        .then(() => {
          this.cancel();
          notifySuccess(`The Appointment Type has been updated`);
        })
        .catch((error: any) => {
          this.loading = false;
          this.DialogEdit = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
  },
});
