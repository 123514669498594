var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-xl overflow-hidden mx-auto",attrs:{"disabled":_vm.loading,"width":"700"}},[_c('v-toolbar',{staticClass:"primary",attrs:{"tag":"div","flat":"","dark":"","tile":""}},[_c('v-toolbar-title',[_vm._v(" Types of Procedures ")]),_c('v-spacer'),_c('v-btn',{attrs:{"loading":_vm.loading,"rounded":"","text":""},on:{"click":function($event){return _vm.listAppointmentTypes()}}},[_c('v-icon',[_vm._v("mdi-autorenew")]),_vm._v(" "+_vm._s(_vm.$t("labelUpdate"))+" ")],1)],1),_c('v-toolbar',{attrs:{"color":"transparent","dense":"","flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('btn-create-appointment-type',{attrs:{"expanded":""},on:{"created":function($event){return _vm.listAppointmentTypes()}}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":'Search ' + _vm.name,"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.appointmenttypes,"items-per-page":10,"item-key":"name","loading":_vm.loading},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == 'ACTIVE')?[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")])]:[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])]]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","origin":"center center","rounded":"lg","close-delay":"5","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""}},[_c('v-btn',{attrs:{"dark":"","color":"red"},on:{"click":function($event){return _vm.gotoDelete(item)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-delete")]),_vm._v("Remove")],1)],1),_c('v-list-item',{attrs:{"link":""}},[_c('btn-edit-appointment-type',{attrs:{"item":item},on:{"edited":_vm.listAppointmentTypes}})],1)],1)],1)]}}],null,true)}),_c('v-dialog',{attrs:{"persistent":"","overlay":false,"max-width":"280","content-class":"rounded-xl"},model:{value:(_vm.dialogConfirmRemove),callback:function ($$v) {_vm.dialogConfirmRemove=$$v},expression:"dialogConfirmRemove"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6 font-weight-regular rounded-xl"},[_vm._v(" "+_vm._s(_vm.$t("dialogTitle"))+" ")]),_c('v-card-text',{attrs:{"column":""}},[_c('div',{staticClass:"text-body-1 text-truncate d-flex mb-1"},[_vm._v(" "+_vm._s(_vm.itemTemp ? _vm.itemTemp.name : "")+" ")])]),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"success","disabled":_vm.loading,"width":"100","text":"","rounded":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" "+_vm._s(_vm.$t("labelCancel"))+" ")]),_c('v-btn',{attrs:{"color":"red","loading":_vm.loading,"width":"100","depressed":"","rounded":"","dark":""},on:{"click":_vm.remove}},[_vm._v(" "+_vm._s(_vm.$t("labelRemove"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }